<template>
  <v-container
    style="max-width: 1200px"
    >
    <Header 
      title="Métodos de pago"
      ></Header>

    <template
      v-if="paymentMethod.name == 'Payku'"
      v-for="paymentMethod in paymentMethods"
      >
      <v-card
        :key="paymentMethod.id"
        class="mb-3"
        >
        <v-card-title
          >
          {{ paymentMethod.name }} 

          <v-spacer></v-spacer>

          <div
            class="body-1 text--primary px-5 py-2 rounded-lg grey lighten-3"
            >
            <v-icon
              class="mt-n1 mr-2"
              color="info"
              >
              mdi-information
            </v-icon>

            Comisión de 2.99% + iva
          </div>
        </v-card-title>

        <v-card-text>
          <PaymentMethod
            :id="paymentMethod.id"
            />
        </v-card-text>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

import { PaymentMethods } from '@/graphql/queries/payment_methods'
const PaymentMethod = () => import('@/components/admin/payment_methods/PaymentMethod')
import Header from '@/components/admin/shared/Header'

export default {
  data () {
    return {
      paymentMethods: []
    }
  },

  created () {
    Vue.use(VuetifyGoogleAutocomplete, {
      vueGoogleMapsCompatibility: true,
    });

    Vue.use(VueGoogleMaps, {
      load: {
        key: "AIzaSyB3RTiKtL9Wx4PT1gwp9FsRGsDu9qJittc",
        libraries: "places", // necessary for places input
        region: process.env.VUE_APP_LOCATION,
        language: "es"
      }
    })
  },

  mounted () {
    this.fetchPaymentMethods()
  },

  components: {
    Header,
    PaymentMethod
  },

  methods: {
    fetchPaymentMethods () {
      this.$apollo.query({
        query: PaymentMethods,
      }).then( res => {
        this.paymentMethods = res.data.paymentMethods
      })
    }
  }
}
</script>
